import React from 'react';

export default function HomeProfile() {
  // Datos de ejemplo
  const user = {
    name: 'Juan',
    profileImage: 'https://tn.com.ar/resizer/v2/maria-becerra-aposto-a-la-formula-jean-mas-jean-una-de-sus-favoritas-foto-instagrammariabecerra-M3XL2A6WWVG47MERARNKRHHANY.jpg?auth=3c18935f420bfd1a708b496a5e135c2b4319175e4fde7db5349087059475302a&width=767',
    followers: 1500,
    following: 200,
    coins: 120,
    videos: [
      {
        id: 'IEUHDUNFUNMOIM',
        description: 'Este es el primer video del usuario Juan',
        videoUrl: 'https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/app%2Fvideo%2FVideo%20de%20WhatsApp%202024-09-25%20a%20las%2016.09.03_db8f4e12.mp4?alt=media&token=8e9599ec-500c-4565-bf65-ffe3a69d962d'
      },
      {
        id: 'IUnfsndiUIbiund',
        description: 'Este es el segundo video del usuario Juan',
        videoUrl: 'https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/app%2Fvideo%2FVideo%20de%20WhatsApp%202024-09-25%20a%20las%2016.09.03_db8f4e12.mp4?alt=media&token=8e9599ec-500c-4565-bf65-ffe3a69d962d'
      }
      // Agrega más videos aquí si es necesario
    ]
  };

  return (
    <div className="container mx-auto p-4">
      {/* Información del usuario */}
      <div className="flex flex-col items-center text-center mb-8">
        <img
          src={user.profileImage}
          alt={`${user.name}'s profile`}
          className="w-32 h-32 rounded-full object-cover border-4 border-gray-300 mb-4"
        />
        <h1 className="text-2xl font-bold mb-2">{user.name}</h1>
        <div className="flex space-x-6">
          <p className="text-gray-700">Seguidores: <span className="font-bold">{user.followers}</span></p>
          <p className="text-gray-700">Siguiendo: <span className="font-bold">{user.following}</span></p>
          <p className="text-gray-700">Monedas: <span className="font-bold">{user.coins}</span></p>
        </div>
      </div>

      {/* Videos del usuario */}
      <div>
        <h2 className="text-xl font-semibold mb-4">Videos</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {user.videos.map((video) => (
            <div key={video.id} className="bg-white p-4 shadow-lg rounded-lg">
              <video className="w-full h-48 object-cover rounded-md mb-2" controls>
                <source src={video.videoUrl} type="video/mp4" />
                Tu navegador no soporta el video.
              </video>
              <p className="text-gray-800">{video.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
