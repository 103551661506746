import React, { useState, useEffect } from "react";
import {
  FaDollarSign,
  FaHistory,
  FaExchangeAlt,
  FaPaperPlane,
  FaUser,
  FaRegComment,
  FaChevronDown,
  FaMoneyCheckAlt,
  FaArrowDown,
  FaArrowUp,
  FaCalendarAlt,
  FaMoneyBillWave,
  FaQuestionCircle,
  FaEnvelope,
  FaArrowLeft,
} from "react-icons/fa";
import useUserData from "../../Hooks/useUserData";
import { getDatabase, ref, get, update } from "firebase/database";
import { motion } from "framer-motion";
import { ChevronRight, Mail, User, Wallet } from "lucide-react";
import { GiMoneyStack, GiPayMoney } from "react-icons/gi";

const WalletView = () => {
  const { userData, loading, error, updateUserData } = useUserData();
  const [amount, setAmount] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const [activeTab, setActiveTab] = useState("transfer");

  const [coinAmount, setCoinAmount] = useState(1);
  const [currency, setCurrency] = useState("COP");
  const [totalPrice, setTotalPrice] = useState(250); // Cada moneda vale 1000 COP

  useEffect(() => {
    if (userData) {
      setTransactionHistory(userData.transacciones || []);
    }
  }, [userData]);

  const handleEmailCheck = async () => {
    const db = getDatabase();
    const usersRef = ref(db, "usuarios");

    try {
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const users = snapshot.val();
        const recipientUser = Object.values(users).find(
          (user) => user.email === recipientEmail
        );
        if (recipientUser) {
          setRecipientName(recipientUser.name || "Usuario");
          setStep(2);
        } else {
          alert("El usuario no existe en el sistema.");
        }
      } else {
        alert("No se encontraron usuarios en el sistema.");
      }
    } catch (error) {
      console.error("Error al verificar el usuario:", error);
      alert("Error al verificar el usuario. Por favor, inténtelo de nuevo.");
    }
  };
  const handleUnknownTransaction = () => {
    // Crear el mensaje con los detalles de la transacción
    const transactionMessage = `Hola, no reconozco esta transacción. Detalles:
    - Tipo: ${selectedTransaction.tipo}
    - Monto: ${selectedTransaction.monto} COP
    - Fecha: ${selectedTransaction.fecha}
    - ${
      selectedTransaction.tipo.includes("enviada")
        ? "Destinatario"
        : "Remitente"
    }: ${selectedTransaction.destinatario || selectedTransaction.remitente}
    - Mensaje: ${selectedTransaction.mensaje || "Ninguno"}
    `;

    // Codificar el mensaje para que sea compatible con el enlace de WhatsApp
    const encodedMessage = encodeURIComponent(transactionMessage);

    // Número de WhatsApp al que se enviará el mensaje
    const whatsappNumber = "+573023580862";

    // Crear el enlace de WhatsApp
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;

    // Redirigir al usuario al enlace de WhatsApp
    window.open(whatsappLink, "_blank");

    // Cerrar el modal
    closeModal();
  };

  const handleTransfer = async () => {
    if (!amount || isNaN(amount) || amount <= 0) {
      alert("Por favor ingrese una cantidad válida");
      return;
    }

    const transferAmount = parseFloat(amount);
    const newSenderSaldo = (userData.saldo || 0) - transferAmount;

    if (newSenderSaldo < 0) {
      alert("Saldo insuficiente");
      return;
    }

    const db = getDatabase();
    const usersRef = ref(db, "usuarios");

    try {
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const users = snapshot.val();
        const recipientUserEntry = Object.entries(users).find(
          ([, user]) => user.email === recipientEmail
        );

        if (recipientUserEntry) {
          const [recipientKey, recipientData] = recipientUserEntry;
          const recipientRef = ref(db, `usuarios/${recipientKey}`);
          const newRecipientSaldo = (recipientData.saldo || 0) + transferAmount;

          const newSenderTransaction = {
            tipo: "Transferencia enviada",
            monto: -transferAmount,
            fecha: new Date().toLocaleString(),
            destinatario: recipientEmail,
            mensaje: message || "",
          };

          const newRecipientTransaction = {
            tipo: "Transferencia recibida",
            monto: transferAmount,
            fecha: new Date().toLocaleString(),
            remitente: userData.email,
            mensaje: message || "",
          };

          await Promise.all([
            updateUserData({
              saldo: newSenderSaldo,
              transacciones: [
                ...(userData.transacciones || []),
                newSenderTransaction,
              ],
            }),
            update(recipientRef, {
              saldo: newRecipientSaldo,
              transacciones: [
                ...(recipientData.transacciones || []),
                newRecipientTransaction,
              ],
            }),
          ]);

          setAmount("");
          setRecipientEmail("");
          setRecipientName("");
          setMessage("");
          setStep(1);
          alert("Transferencia exitosa");
        } else {
          alert("El usuario destinatario no se encuentra en la base de datos.");
        }
      } else {
        alert("No se encontraron usuarios en el sistema.");
      }
    } catch (error) {
      console.error("Error al realizar la transferencia:", error);
      alert(
        "Error al realizar la transferencia. Por favor, inténtelo de nuevo."
      );
    }
  };

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedTransaction(null);
  };

  // Manejar el cambio en la cantidad de monedas
  const handleCoinAmountChange = (e) => {
    const amount = e.target.value;
    setCoinAmount(amount);
    setTotalPrice(amount * 250); // 1 moneda = 1000 COP
  };

  // Manejar el cambio de moneda
  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  // Manejar la recarga y redirigir a WhatsApp
  const handleRecharge = () => {
    const rechargeMessage = `Hola, quiero recargar ${coinAmount} monedas. Detalles:
- Cantidad de monedas: ${coinAmount}
- Precio Total: ${
      currency === "COP"
        ? `${totalPrice} COP`
        : `${(totalPrice / 4200).toFixed(2)} USD`
    }`;

    const encodedMessage = encodeURIComponent(rechargeMessage);
    const whatsappNumber = "+573023580862";
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;

    // Redirigir al usuario a WhatsApp
    window.open(whatsappLink, "_blank");
  };

  const filteredTransactions = transactionHistory.sort(
    (a, b) => new Date(b.fecha) - new Date(a.fecha)
  );
  const displayedTransactions = viewMore
    ? filteredTransactions
    : filteredTransactions.slice(0, 7);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center p-4">Error: {error}</div>;
  }

  return (
    <div className="max-w-md mx-auto bg-gray-900 rounded-xl shadow-md min-h-screen flex flex-col ">
      <header className="bg-gray-800 p-4 flex items-center justify-between shadow-lg">
        {/* Botón de ir atrás */}
        <button
          className="text-white flex items-center space-x-2 hover:text-blue-400"
          onClick={() => window.history.back()}
        >
          <FaArrowLeft className="text-lg" />
          <span>Atrás</span>
        </button>

        {/* Título centrado */}
        <h1 className="text-white text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">
          Pro. Wallet
        </h1>

        {/* Logo a la derecha */}
        <div className="flex items-center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/logoBlgo%2Ficon512x512preview.png?alt=media&token=00445cc5-773d-43aa-862e-6c437b48bce2"
            alt="Logo de la App"
            className="h-10 w-10 object-contain"
          />
        </div>
      </header>

      {/* Saldo disponible */}
      <div className="space-y-6 p-7">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 text-gray-700 dark:text-gray-200">
              <Wallet className="w-6 h-6 text-blue-500 dark:text-blue-400" />
              <span className="text-sm font-semibold text-white">
                Monedas disponibles
              </span>
            </div>
            <ChevronRight className="w-5 h-5 text-gray-400 hover:text-gray-600 cursor-pointer" />
          </div>
          <div className="text-3xl font-bold text-green-600 dark:text-green-400 pl-9">
            ${userData.saldo?.toLocaleString() || "0"}
          </div>
        </div>

        {/* Información de cuenta */}
        <div className="space-y-3 pt-4 border-t border-gray-200 dark:border-gray-500">
          <div className="flex items-center gap-3 text-white dark:text-gray-200">
            <User className="w-5 h-5 text-blue-500 dark:text-blue-400" />
            <span className="text-sm">
              <span className="font-medium text-sky-600 dark:text-gray-100">
                Cuenta:
              </span>{" "}
              {userData.name || "Usuario"}
            </span>
          </div>

          <div className="flex items-center gap-3 text-white dark:text-gray-200">
            <Mail className="w-5 h-5 text-blue-500 dark:text-blue-400" />
            <span className="text-sm">
              <span className="font-medium text-sky-600 dark:text-gray-400">
                Email:
              </span>{" "}
              {userData.email || "usuario@ejemplo.com"}
            </span>
          </div>
        </div>
      </div>

      {/* Tabs mejorados para móvil */}
<div className="flex justify-center mb-4 pb-4 pt-6 overflow-x-auto w-full bg-gray-800 shadow-md rounded-lg">
  <button
    className={`px-3 py-2 font-medium flex items-center justify-center space-x-1 w-full text-center rounded-md transition duration-200 ease-in-out transform hover:scale-105 ${
      activeTab === "transfer"
        ? "text-white bg-blue-600 shadow-md"
        : "text-gray-400 bg-gray-700"
    }`}
    onClick={() => setActiveTab("transfer")}
  >
    {/* Ícono de transferencias */}
    <i className="fas fa-exchange-alt text-base"></i>
    <span className="text-sm">Transferencias</span>
  </button>

  <button
    className={`px-3 py-2 font-medium flex items-center justify-center space-x-1 w-full text-center rounded-md transition duration-200 ease-in-out transform hover:scale-105 ${
      activeTab === "history"
        ? "text-white bg-blue-600 shadow-md"
        : "text-gray-400 bg-gray-700"
    }`}
    onClick={() => setActiveTab("history")}
  >
    {/* Ícono de historial */}
    <i className="fas fa-history text-base"></i>
    <span className="text-sm">Historial</span>
  </button>

  <button
    className={`px-3 py-2 font-medium flex items-center justify-center space-x-1 w-full text-center rounded-md transition duration-200 ease-in-out transform hover:scale-105 ${
      activeTab === "recharge"
        ? "text-white bg-blue-600 shadow-md"
        : "text-gray-400 bg-gray-700"
    }`}
    onClick={() => setActiveTab("recharge")}
  >
    {/* Ícono de recargar */}
    <GiPayMoney className="text-base" />
    <span className="text-sm">Recargar</span>
  </button>
</div>


      {/* Transferencias */}
      {activeTab === "transfer" && (
        <div className="bg-gray-800 p-6 rounded-lg mb-6 shadow-md">
          <h2 className="text-2xl text-white mb-4 font-semibold">
            Realizar Transferencia
          </h2>
          {step === 1 ? (
            <>
              <div className="flex items-center space-x-2 mb-4">
                <FaUser className="text-white text-xl" />
                <input
                  type="email"
                  placeholder="Correo del destinatario"
                  className="p-3 rounded-lg bg-gray-700 text-white w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={recipientEmail}
                  onChange={(e) => setRecipientEmail(e.target.value)}
                />
              </div>
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-lg w-full flex items-center justify-center space-x-2 transition duration-300"
                onClick={handleEmailCheck}
              >
                <FaExchangeAlt />
                <span>Verificar Destinatario</span>
              </button>
            </>
          ) : (
            <>
              <p className="text-white mb-4">
                Destinatario: {recipientName} ({recipientEmail})
              </p>
              <div className="flex items-center space-x-2 mb-4">
                <FaDollarSign className="text-white text-xl" />
                <input
                  type="number"
                  placeholder="Cantidad a transferir"
                  className="p-3 rounded-lg bg-gray-700 text-white w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="flex items-center space-x-2 mb-4">
                <FaRegComment className="text-white text-xl" />
                <input
                  type="text"
                  placeholder="Mensaje opcional"
                  className="p-3 rounded-lg bg-gray-700 text-white w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded-lg w-full flex items-center justify-center space-x-2 transition duration-300"
                onClick={handleTransfer}
              >
                <FaPaperPlane />
                <span>Transferir</span>
              </button>
            </>
          )}
        </div>
      )}

      {/* Historial */}
      {activeTab === "history" && (
        <div className="bg-gray-900 p-1 rounded-lg shadow-lg">
          <h2 className="text-3xl text-white mb-6 font-bold flex items-center justify-center space-x-2">
            <FaMoneyCheckAlt className="text-blue-500" />
            <span>Historial</span>
          </h2>

          <ul className="divide-y divide-gray-700 space-y-4">
            {displayedTransactions
              .slice() // Hacer una copia del array
              .reverse() // Ordenar de más reciente a más antigua
              .map((transaction, index) => (
                <motion.li
                  key={index}
                  className="bg-gray-800 p-4 rounded-lg shadow-md cursor-pointer hover:bg-gray-700 transition duration-300 ease-in-out transform hover:scale-105"
                  onClick={() => handleTransactionClick(transaction)}
                >
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center space-x-2">
                      {transaction.monto < 0 ? (
                        <FaArrowDown className="text-red-500" />
                      ) : (
                        <FaArrowUp className="text-green-500" />
                      )}
                      <p className="text-white font-semibold text-lg">
                        {transaction.tipo}
                      </p>
                    </div>
                    <p
                      className={`font-bold text-lg ${
                        transaction.monto < 0
                          ? "text-red-400"
                          : "text-green-400"
                      }`}
                    >
                      {transaction.monto} COP
                    </p>
                  </div>
                  <div className="flex items-center text-gray-400 text-sm">
                    <FaCalendarAlt className="mr-2" />
                    <p>{transaction.fecha}</p>
                  </div>
                </motion.li>
              ))}
          </ul>

          {/* Botón para ver más o menos transacciones */}
          {filteredTransactions.length > 7 && (
            <div className="flex justify-center mt-6">
              <button
                className="text-blue-400 font-semibold flex items-center space-x-1 hover:text-blue-300 transition-colors duration-300"
                onClick={() => setViewMore(!viewMore)}
              >
                <span>{viewMore ? "Ver menos" : "Ver más"}</span>
                <FaChevronDown
                  className={`transition-transform ${
                    viewMore ? "rotate-180" : ""
                  }`}
                />
              </button>
            </div>
          )}
        </div>
      )}

      {/* Recargar */}
      {activeTab === "recharge" && (
        <div className="bg-gray-800 p-6 rounded-lg mb-6 shadow-md">
          <h2 className="text-2xl text-white mb-4 font-semibold">
            Realizar Recargas
          </h2>

          {/* Cantidad de monedas */}
          <div className="mb-4">
            <label htmlFor="coinAmount" className="text-white font-semibold">
              Ingresa la cantidad de monedas:
            </label>
            <input
              type="number"
              id="coinAmount"
              className="w-full mt-2 p-2 rounded bg-gray-700 text-white"
              placeholder="Cantidad de monedas"
              value={coinAmount}
              onChange={handleCoinAmountChange}
              min="1"
            />
          </div>

          {/* Selección de moneda */}
          <div className="mb-4">
            <label htmlFor="currency" className="text-white font-semibold">
              Selecciona la moneda:
            </label>
            <select
              id="currency"
              className="w-full mt-2 p-2 rounded bg-gray-700 text-white"
              value={currency}
              onChange={handleCurrencyChange}
            >
              <option value="COP">COP</option>
              <option value="USD">USD</option>
            </select>
          </div>

          {/* Mostrar precio en tiempo real */}
          <div className="text-white mb-4">
            <p className="font-semibold">
              Precio Total:{" "}
              {currency === "COP"
                ? `${totalPrice} COP`
                : `${(totalPrice / 4200).toFixed(2)} USD`}
            </p>
          </div>

          {/* Botón de recarga */}
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg w-full"
            onClick={handleRecharge}
          >
            Ir a Recargar
          </button>
        </div>
      )}

      {/* Modal de Detalles */}
      {modalOpen && selectedTransaction && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-gray-900 p-6 rounded-lg shadow-xl w-96 border border-gray-700">
            <h2 className="text-3xl text-white mb-6 font-bold flex items-center space-x-2">
              <FaMoneyBillWave className="text-yellow-400" />
              <span>Detalles de la Transacción</span>
            </h2>
            <div className="text-white space-y-4">
              <div className="flex items-center">
                <FaMoneyBillWave className="text-blue-400 mr-3" />
                <p>
                  <strong>Tipo:</strong> {selectedTransaction.tipo}
                </p>
              </div>
              <div className="flex items-center">
                <FaMoneyBillWave className="text-green-400 mr-3" />
                <p>
                  <strong>Monto:</strong> {selectedTransaction.monto} COP
                </p>
              </div>
              <div className="flex items-center">
                <FaCalendarAlt className="text-gray-400 mr-3" />
                <p>
                  <strong>Fecha:</strong> {selectedTransaction.fecha}
                </p>
              </div>
              <div className="flex items-center">
                <FaUser className="text-yellow-400 mr-3" />
                <p>
                  <strong>
                    {selectedTransaction.tipo.includes("enviada")
                      ? "Destinatario"
                      : "Remitente"}
                    :
                  </strong>{" "}
                  {selectedTransaction.destinatario ||
                    selectedTransaction.remitente}
                </p>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="text-blue-400 mr-3" />
                <p>
                  <strong>Mensaje:</strong>{" "}
                  {selectedTransaction.mensaje || "Ninguno"}
                </p>
              </div>
            </div>

            <button
              className="mt-6 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg w-full"
              onClick={closeModal}
            >
              Cerrar
            </button>

            {/* Botón de ayuda para reportar la transacción */}
            <button
              className="mt-4 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-lg w-full flex items-center justify-center space-x-2"
              onClick={handleUnknownTransaction}
            >
              <FaQuestionCircle className="text-white" />
              <span>Desconozco esta transacción</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletView;
