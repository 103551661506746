import React, { useState, useEffect } from "react";
import { ShoppingCart, HelpCircle, Home, Search, Star } from "lucide-react";
import { getDatabase, ref, onValue } from "firebase/database"; // Import Firebase Database

// Componentes personalizados
const Button = ({ children, variant = "default", size = "default", className = "", ...props }) => {
  const baseStyles = "font-medium rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2";
  const variantStyles = {
    default: "bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500",
    outline: "border-2 border-gray-300 hover:bg-gray-100 focus:ring-gray-500",
    ghost: "hover:bg-gray-100 focus:ring-gray-500"
  };
  const sizeStyles = {
    default: "px-4 py-2",
    lg: "px-6 py-3 text-lg",
    icon: "p-2"
  };
  return (
    <button className={`${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]} ${className}`} {...props}>
      {children}
    </button>
  );
};

const Card = ({ children, className = "", ...props }) => {
  return <div className={`bg-white rounded-lg shadow-md overflow-hidden ${className}`} {...props}>{children}</div>;
};

const CardContent = ({ children, className = "", ...props }) => {
  return <div className={`p-4 ${className}`} {...props}>{children}</div>;
};

const CardFooter = ({ children, className = "", ...props }) => {
  return <div className={`px-4 pb-4 ${className}`} {...props}>{children}</div>;
};

const Badge = ({ children, className = "", ...props }) => {
  return <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 ${className}`} {...props}>{children}</span>;
};

const Input = ({ className = "", ...props }) => {
  return <input className={`w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${className}`} {...props} />;
};

const Store = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [products, setProducts] = useState([]); // Estado para productos

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

 useEffect(() => {
  const db = getDatabase();
  const storeRef = ref(db, 'App/store/products'); // Cambia a 'products'
  onValue(storeRef, (snapshot) => {
    const data = snapshot.val();
    if (data) {
      const productList = Object.values(data);
      setProducts(productList);
    }
  });
}, []);


  // Categorías destacadas
  const categories = [
    { name: "Auriculares", icon: "🎧" },
    { name: "Smartwatches", icon: "⌚" },
    { name: "Tablets", icon: "📱" },
    { name: "Laptops", icon: "💻" },
  ];

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Navbar */}
      <nav className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? "bg-white shadow-md py-2" : "bg-transparent py-4"}`}>
        <div className="container mx-auto flex justify-between items-center px-4">
          <a href="#" className={`font-bold text-2xl ${isScrolled ? "text-gray-800" : "text-white"}`}>TechStore</a>
          <div className="hidden md:flex flex-1 max-w-md mx-6">
            <div className="relative w-full">
              <Input type="text" placeholder="Buscar productos..." className="pl-10 pr-4 py-2 rounded-full" />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>
          <ul className="flex space-x-6 items-center">
            <li>
              <Button variant="ghost" className={isScrolled ? "text-gray-800" : "text-white"}>
                <Home size={20} />
                <span className="hidden sm:inline ml-2">Inicio</span>
              </Button>
            </li>
            <li>
              <Button variant="ghost" className={isScrolled ? "text-gray-800" : "text-white"}>
                <HelpCircle size={20} />
                <span className="hidden sm:inline ml-2">Ayuda</span>
              </Button>
            </li>
          </ul>
        </div>
      </nav>

      {/* Categories Section */}
      <div className="container mx-auto px-6 py-12 bg-gray-900">
        <h2 className="text-3xl font-bold text-white mb-8">Categorías</h2>
        <div className="flex flex-col space-y-4">
          {categories.map((category) => (
            <Card key={category.name} className="hover:shadow-lg transition-shadow cursor-pointer">
              <CardContent className="flex items-center space-x-4 p-6">
                <span className="text-4xl">{category.icon}</span>
                <h3 className="text-lg font-semibold text-white">{category.name}</h3>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      {/* Products Section */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-gray-800 mb-8">Productos Destacados</h2>
          <div className="flex overflow-x-auto space-x-6">
            {products.map((product) => (
              <Card key={product.id} className="min-w-[300px] hover:shadow-xl transition-shadow">
                <img src={product.image} alt={product.name} className="w-full h-48 object-cover" />
                <CardContent>
                  <Badge className="mb-2">{product.category}</Badge>
                  <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
                  <div className="flex items-center mb-2">
                    <Star className="text-yellow-400 fill-current" size={16} />
                    <span className="ml-1 text-sm text-gray-600">{product.rating} ({product.reviews} reseñas)</span>
                  </div>
                  <p className="text-2xl font-bold text-blue-600">{product.price}</p>
                </CardContent>
                <CardFooter className="flex justify-between">
                  <Button className="flex-1 mr-2">Comprar</Button>
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Store;
 